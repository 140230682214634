import { createElement } from "react";
import * as react from "react";
import { printf, toConsole, interpolate, toText, join } from "./.fable/fable-library.3.1.16/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { toArray as toArray_1, filter, head, contains, item, length, sortBy, map, ofArray, singleton, empty, cons } from "./.fable/fable-library.3.1.16/List.js";
import { Interop_reactApi } from "./.fable/Feliz.1.62.0/Interop.fs.js";
import { Content, ButtonProgressLoader, Row, Col } from "./UtilComponents.fs.js";
import single$002Dproducts$002Emodule from "./styles/single-products.module.scss";
import { Recoil_useRecoilState_Z74A95619, Recoil_useRecoilValue_5D3EEEEA } from "./.fable/Feliz.Recoil.0.19.2/Bindings.fs.js";
import { config as config_1, solanaEnvironment, tokens as tokens_3, selectedProductType, selectedInjectiveProductAddress as selectedInjectiveProductAddress_1, injectiveEnvironment as injectiveEnvironment_1, selectedProductPublicKey as selectedProductPublicKey_1, filteredView as filteredView_1, assistant as assistant_1, SelectedProductGroup, themeConfig as themeConfig_1, selectedProductGroup as selectedProductGroup_1, blockchain } from "./Context.fs.js";
import { toArray, empty as empty_1, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.1.16/Seq.js";
import { equals as equals_1, fromParts, op_Division, parse, compare } from "./.fable/fable-library.3.1.16/Decimal.js";
import { compareArrays, compare as compare_1, comparePrimitives, arrayHash, equalArrays, safeHash, equals } from "./.fable/fable-library.3.1.16/Util.js";
import { Kind } from "../../../lib/Domain/Types/FlexProduct.fs.js";
import { CurrencyIcon, CurrencyIconProductList } from "./Currency.fs.js";
import { Array_groupBy, List_groupBy } from "./.fable/fable-library.3.1.16/Seq2.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505, useReact_useMemo_CF4EA67 } from "./.fable/Feliz.1.62.0/React.fs.js";
import { processInjectiveHoldingProductData, processFlexProductData, processExoticProductData, filterHoldingProductsUsingAssistant } from "./HoldingData.fs.js";
import { concat, chunkBySize, map2, equalsWith, contains as contains_1, tryFind, sortBy as sortBy_1, map as map_1 } from "./.fable/fable-library.3.1.16/Array.js";
import { QueryRfqsByIds$reflection, QueryRfqsByIds, QueryOptionsByIds$reflection, QueryOptionsByIds, HoldingState, deserializeHolding, QueryHoldings$reflection, QueryHoldings, InjectiveHolding__GetYieldProductType_Z4C3DC210, YieldProductType__GetBaseDenom } from "../../../lib/Domain/Types/InjectiveHolding.fs.js";
import { some, map as map_2 } from "./.fable/fable-library.3.1.16/Option.js";
import { getNetworkByInjectiveEnvironment, getOracleList, indexerGrpcOracleApi, getStringArrayFromJsonString, getWasmSmartQuery, chainGrpcWasmApi, estimatedInjectiveHoldingYield } from "../../../lib/Injective/Injective.fs.js";
import { deserializeProduct, QueryProducts$reflection, QueryProducts, Ids, InjectiveProduct__GetKind, InjectiveProductKind } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import { Hooks_useSubscribeObservable, Hooks_useSplitObservableWithUpdates, Hooks_useStreamToObservable, Hooks_useHub } from "../../../lib/Fable.Remoting.SignalR/SignalR.fs.js";
import { Api, signalRproxy } from "./Server.fs.js";
import { merge } from "./.fable/fable-library.3.1.16/Observable.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../lib/Notistack/Notistack.fs.js";
import { parallel, startImmediate } from "./.fable/fable-library.3.1.16/Async.js";
import { singleton as singleton_2 } from "./.fable/fable-library.3.1.16/AsyncBuilder.js";
import { Result_Map } from "./.fable/fable-library.3.1.16/Choice.js";
import { PublicKey } from "../../../lib/Domain/Types/Common.fs.js";
import { errorBoundary } from "./Error.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, toString } from "./.fable/Thoth.Json.10.1.0/Encode.fs.js";
import { CaseStrategy } from "./.fable/Thoth.Json.10.1.0/Types.fs.js";
import { utcNow } from "./.fable/fable-library.3.1.16/DateOffset.js";
import { compare as compare_2 } from "./.fable/fable-library.3.1.16/Date.js";
import { deserializeOptionArray } from "../../../lib/Domain/Types/InjectiveOption.fs.js";
import { rangeDouble } from "./.fable/fable-library.3.1.16/Range.js";
import { deserializeRfqArray } from "../../../lib/Domain/Types/InjectiveRfq.fs.js";
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { FlexProductGroup } from "./FlexProductDetailGroup.fs.js";
import { ExoticProductGroupDetail } from "./ExoticProductGroupDetail.fs.js";
import { InjectiveHoldingGroupDetail } from "./InjectiveHoldingGroupDetail.fs.js";

export function subHeading(text, classes) {
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule["margin-xs-top"], classes)),
        children: Interop_reactApi.Children.toArray([text]),
    });
}

export function Header() {
    return createElement(Col, {
        classes: empty(),
        children: singleton(createElement(Col, {
            classes: singleton(utils$002Emodule["gap-m"]),
            children: singleton(createElement("div", {
                className: join(" ", ["header-5", utils$002Emodule["margin-l-top"]]),
                children: "Dual-Currency Yield Products",
            })),
        })),
    });
}

export function RenderHeadersDcnCall() {
    return createElement("div", {
        className: join(" ", [utils$002Emodule["margin-l-top"], utils$002Emodule["margin-l-bottom"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["list-header-upside"], utils$002Emodule["margin-s-bottom"]]),
            children: Interop_reactApi.Children.toArray(["Dual-Currency Upside Products"]),
        }), subHeading("Deposit a token and generates yield by selling a covered call.", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]])), subHeading("NOT recommended if you predict a strong bull market (market up)", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]])), subHeading("The capital deposited is not lent out and remains on-chain", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]])), subHeading("(SECRET WORD INJECTIVE CAMPAIGN: WATER)", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]]))]),
    });
}

export function RenderHeadersDcnPut() {
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    return createElement("div", {
        className: join(" ", [utils$002Emodule["margin-l-top"], utils$002Emodule["margin-l-bottom"]]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["list-header-downside"], utils$002Emodule["margin-s-bottom"]]),
            children: Interop_reactApi.Children.toArray(["Dual-Currency Downside Products"]),
        })), delay(() => append(singleton_1(subHeading((chain.tag === 1) ? "Deposit USDT and generates USDT yield by selling a covered put" : "Deposit USDC and generates USDC yield by selling a covered put", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]]))), delay(() => append(singleton_1(subHeading("NOT recommended if you predict a strong bear market (market down)", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]]))), delay(() => singleton_1(subHeading("The capital deposited is not lent out and remains on-chain", ofArray([single$002Dproducts$002Emodule["list-header-subheading"], utils$002Emodule["margin-s-bottom"]]))))))))))))),
    });
}

export function RenderHeadersDcnWorstOfPut() {
    return createElement("div", {
        className: join(" ", [utils$002Emodule["margin-l-top"], utils$002Emodule["margin-l-bottom"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["list-header-put-worstoff"]]),
            children: Interop_reactApi.Children.toArray(["Worst of Put"]),
        }), subHeading("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ornare eget quam sed dapibus. Vestibulum ante ipsum primis in faucibus.", singleton(single$002Dproducts$002Emodule["list-header-subheading"])), subHeading("Subheading 2", singleton(single$002Dproducts$002Emodule["list-header-subheading"])), subHeading("Subheading 3", singleton(single$002Dproducts$002Emodule["list-header-subheading"]))]),
    });
}

export function RenderProductCard(renderProductCardInputProps) {
    const onSelected = renderProductCardInputProps.onSelected;
    const isReccomended = renderProductCardInputProps.isReccomended;
    const kind = renderProductCardInputProps.kind;
    const products = renderProductCardInputProps.products;
    const patternInput = Recoil_useRecoilState_Z74A95619(selectedProductGroup_1);
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    return react.createElement(react.Fragment, {}, ...map((tupledArg_1) => {
        const sortedList = sortBy((tupledArg_2) => tupledArg_2[0].EstimatedYieldHigh, tupledArg_1[1], {
            Compare: (x_1, y_1) => compare(x_1, y_1),
        });
        const groupCount = length(sortedList) | 0;
        const patternInput_1 = [item(0, sortedList), item(0, sortedList)[0].EstimatedYieldHigh, item(groupCount - 1, sortedList)[0].EstimatedYieldHigh];
        const tokens = patternInput_1[0][1];
        return createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["list-group-card"], (themeConfig === "theme-dark") ? single$002Dproducts$002Emodule["list-group-card-dark"] : ""]),
            onClick: (_arg3) => {
                patternInput[1](new SelectedProductGroup(0, tupledArg_1[0], kind));
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                className: join(" ", toList(delay(() => append(singleton_1(single$002Dproducts$002Emodule["list-group-card-header"]), delay(() => append(singleton_1((themeConfig === "theme-dark") ? single$002Dproducts$002Emodule["list-group-card-header-dark"] : ""), delay(() => (equals(kind, new Kind(2)) ? singleton_1(single$002Dproducts$002Emodule["list-group-card-header-multi"]) : empty_1())))))))),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((kind.tag === 2) ? append(singleton_1(createElement(CurrencyIconProductList, {
                    productPaidLeft: tokens.ProductPairRight,
                    productPaidRight: tokens.ProductPairLeft,
                })), delay(() => singleton_1(toText(interpolate("%P()-%P()", [tokens.ProductPairRight.symbol, tokens.ProductPairLeft.symbol]))))) : ((kind.tag === 0) ? singleton_1(null) : append(singleton_1(createElement(CurrencyIcon, {
                    depositMint: tokens.ProductPairLeft,
                })), delay(() => singleton_1(toText(interpolate("%P()", [tokens.ProductPairLeft.symbol]))))))))))),
            })), delay(() => {
                const patternInput_2 = (kind.tag === 2) ? ["Downside", single$002Dproducts$002Emodule["text-downside"]] : ((kind.tag === 0) ? ["Undefined", ""] : ["Upside", single$002Dproducts$002Emodule["text-upside"]]);
                return append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-subheader"], patternInput_2[1], utils$002Emodule["margin-m-top"]]),
                    children: Interop_reactApi.Children.toArray([toText(interpolate("%P()", [patternInput_2[0]]))]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-line"], utils$002Emodule["margin-m-top"]]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy"], utils$002Emodule["margin-m-top"]]),
                    children: Interop_reactApi.Children.toArray([toText(interpolate("Upto %P()%%", [patternInput_1[2]]))]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy-subheader"]]),
                    children: Interop_reactApi.Children.toArray([toText(interpolate("Estimated APY", []))]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-line"], utils$002Emodule["margin-m-top"]]),
                })), delay(() => singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy-subheader"], utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-bottom"]]),
                    children: Interop_reactApi.Children.toArray([(groupCount > 1) ? toText(interpolate("%P() available products", [groupCount])) : toText(interpolate("%P() available product", [groupCount]))]),
                }))))))))))));
            })))))),
        });
    }, List_groupBy((tupledArg) => tupledArg[0].UnderlyingMint, products, {
        Equals: (x, y) => equals(x, y),
        GetHashCode: (x) => safeHash(x),
    })));
}

export function DualCurrencyProducts(dualCurrencyProductsInputProps) {
    const setLoading = dualCurrencyProductsInputProps.setLoading;
    const isLoading = dualCurrencyProductsInputProps.isLoading;
    const kind = dualCurrencyProductsInputProps.kind;
    const onSelected = dualCurrencyProductsInputProps.onSelected;
    const products = dualCurrencyProductsInputProps.products;
    const assistant = Recoil_useRecoilValue_5D3EEEEA(assistant_1);
    const filteredView = Recoil_useRecoilValue_5D3EEEEA(filteredView_1);
    const reccomendedProducts = useReact_useMemo_CF4EA67(() => filterHoldingProductsUsingAssistant(assistant, products), [[assistant, products]]);
    return createElement(Row, {
        classes: ofArray([utils$002Emodule["gap-m"], single$002Dproducts$002Emodule["products-list"]]),
        children: toList(delay(() => (isLoading ? singleton_1(createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["full-width"], "text-exo-primary"]),
            children: singleton(createElement(ButtonProgressLoader, {
                classes: empty(),
            })),
        })) : singleton_1(createElement(RenderProductCard, {
            products: products,
            kind: kind,
            isReccomended: (product) => ((tokens) => {
                if (filteredView) {
                    if (assistant != null) {
                        toConsole(printf("Recommended %A"))(reccomendedProducts);
                        return contains([product, tokens], reccomendedProducts, {
                            Equals: (x, y) => equalArrays(x, y),
                            GetHashCode: (x) => arrayHash(x),
                        });
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }),
            onSelected: onSelected,
        }))))),
    });
}

export function RenderInjectiveHoldingCard(renderInjectiveHoldingCardInputProps) {
    const onSelected = renderInjectiveHoldingCardInputProps.onSelected;
    const isReccomended = renderInjectiveHoldingCardInputProps.isReccomended;
    const kind = renderInjectiveHoldingCardInputProps.kind;
    const oracles = renderInjectiveHoldingCardInputProps.oracles;
    const products = renderInjectiveHoldingCardInputProps.products;
    const patternInput = Recoil_useRecoilState_Z74A95619(selectedProductGroup_1);
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    return react.createElement(react.Fragment, {}, ...map_1((tupledArg_2) => {
        const yieldProductType_1 = tupledArg_2[0];
        const underlyingMint = YieldProductType__GetBaseDenom(yieldProductType_1);
        const sortedList = sortBy_1((tupledArg_3) => {
            const p = tupledArg_3[0];
            let spot;
            const matchValue_1 = [map_2((s) => parse(s.price), tryFind((o) => {
                if (o.symbol === ("0x" + p.Product.OracleBase)) {
                    return o.oracleType === "pyth";
                }
                else {
                    return false;
                }
            }, oracles)), map_2((s_1) => parse(s_1.price), tryFind((o_1) => {
                if (o_1.symbol === ("0x" + p.Product.OracleQuote)) {
                    return o_1.oracleType === "pyth";
                }
                else {
                    return false;
                }
            }, oracles))];
            let pattern_matching_result, b, q;
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] != null) {
                    pattern_matching_result = 0;
                    b = matchValue_1[0];
                    q = matchValue_1[1];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    spot = op_Division(b, q);
                    break;
                }
                case 1: {
                    spot = fromParts(0, 0, 0, false, 0);
                    break;
                }
            }
            const strikeOption = map_2((op) => op.Strike, p.Option);
            if (equals_1(spot, fromParts(0, 0, 0, false, 0))) {
                return fromParts(0, 0, 0, false, 0);
            }
            else {
                return estimatedInjectiveHoldingYield(p.Product, p.Holding.FairPrice, spot, strikeOption);
            }
        }, tupledArg_2[1], {
            Compare: (x_2, y_2) => compare(x_2, y_2),
        });
        const groupCount = sortedList.length | 0;
        let patternInput_1;
        let spot_1;
        const matchValue_2 = [map_2((s_2) => parse(s_2.price), tryFind((o_2) => {
            if (o_2.symbol === ("0x" + sortedList[0][0].Product.OracleBase)) {
                return o_2.oracleType === "pyth";
            }
            else {
                return false;
            }
        }, oracles)), map_2((s_3) => parse(s_3.price), tryFind((o_3) => {
            if (o_3.symbol === ("0x" + sortedList[0][0].Product.OracleQuote)) {
                return o_3.oracleType === "pyth";
            }
            else {
                return false;
            }
        }, oracles))];
        let pattern_matching_result_1, b_1, q_1;
        if (matchValue_2[0] != null) {
            if (matchValue_2[1] != null) {
                pattern_matching_result_1 = 0;
                b_1 = matchValue_2[0];
                q_1 = matchValue_2[1];
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                spot_1 = op_Division(b_1, q_1);
                break;
            }
            case 1: {
                spot_1 = fromParts(0, 0, 0, false, 0);
                break;
            }
        }
        const strikeOptionfst = map_2((op_1) => op_1.Strike, sortedList[0][0].Option);
        const strikeOptionlast = map_2((op_2) => op_2.Strike, sortedList[groupCount - 1][0].Option);
        patternInput_1 = [sortedList[0], equals_1(spot_1, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : estimatedInjectiveHoldingYield(sortedList[0][0].Product, sortedList[0][0].Holding.FairPrice, spot_1, strikeOptionfst), equals_1(spot_1, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : estimatedInjectiveHoldingYield(sortedList[groupCount - 1][0].Product, sortedList[groupCount - 1][0].Holding.FairPrice, spot_1, strikeOptionlast)];
        const tokens = patternInput_1[0][1];
        return createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["list-group-card"], (themeConfig === "theme-dark") ? single$002Dproducts$002Emodule["list-group-card-dark"] : ""]),
            onClick: (_arg4) => {
                toConsole(printf("%A"))(underlyingMint);
                patternInput[1](new SelectedProductGroup(2, underlyingMint, kind));
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                className: join(" ", toList(delay(() => append(singleton_1(single$002Dproducts$002Emodule["list-group-card-header"]), delay(() => append(singleton_1((themeConfig === "theme-dark") ? single$002Dproducts$002Emodule["list-group-card-header-dark"] : ""), delay(() => (equals(kind, new InjectiveProductKind(1)) ? singleton_1(single$002Dproducts$002Emodule["list-group-card-header-multi"]) : empty_1())))))))),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((kind.tag === 1) ? append(singleton_1(createElement(CurrencyIconProductList, {
                    productPaidLeft: tokens.ProductPairRight,
                    productPaidRight: tokens.ProductPairLeft,
                })), delay(() => singleton_1(toText(interpolate("%P()-%P()", [tokens.ProductPairRight.symbol, tokens.ProductPairLeft.symbol]))))) : append(singleton_1(createElement(CurrencyIcon, {
                    depositMint: tokens.ProductPairLeft,
                })), delay(() => singleton_1(toText(interpolate("%P()", [tokens.ProductPairLeft.symbol])))))))))),
            })), delay(() => {
                const patternInput_2 = (yieldProductType_1.tag === 0) ? ["Upside", single$002Dproducts$002Emodule["text-upside"]] : ((yieldProductType_1.tag === 1) ? ["Downside", single$002Dproducts$002Emodule["text-downside"]] : ["Upside + Staking", single$002Dproducts$002Emodule["text-upside"]]);
                return append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-subheader"], patternInput_2[1], utils$002Emodule["margin-m-top"]]),
                    children: Interop_reactApi.Children.toArray([toText(interpolate("%P()", [patternInput_2[0]]))]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-line"], utils$002Emodule["margin-m-top"]]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy"], utils$002Emodule["margin-m-top"]]),
                    children: Interop_reactApi.Children.toArray([toText(interpolate("Upto %P()%%", [patternInput_1[2]]))]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy-subheader"]]),
                    children: Interop_reactApi.Children.toArray([toText(interpolate("Estimated APY", []))]),
                })), delay(() => append(singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-line"], utils$002Emodule["margin-m-top"]]),
                })), delay(() => singleton_1(createElement("div", {
                    className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy-subheader"], utils$002Emodule["margin-l-top"], utils$002Emodule["margin-m-bottom"]]),
                    children: Interop_reactApi.Children.toArray([(groupCount > 1) ? toText(interpolate("%P() available products", [groupCount])) : toText(interpolate("%P() available product", [groupCount]))]),
                }))))))))))));
            })))))),
        });
    }, sortBy_1((tupledArg_1) => {
        const matchValue = YieldProductType__GetBaseDenom(tupledArg_1[0]).toLocaleLowerCase();
        switch (matchValue) {
            case "inj": {
                return 0;
            }
            case "peggy0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": {
                return 1;
            }
            case "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9": {
                return 2;
            }
            default: {
                return 3;
            }
        }
    }, Array_groupBy((tupledArg) => {
        const product = tupledArg[0];
        return InjectiveHolding__GetYieldProductType_Z4C3DC210(product.Holding, product.Product);
    }, products, {
        Equals: (x, y) => equals(x, y),
        GetHashCode: (x) => safeHash(x),
    }), {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    })));
}

export function InjectiveHoldingProducts(injectiveHoldingProductsInputProps) {
    const setLoading = injectiveHoldingProductsInputProps.setLoading;
    const isLoading = injectiveHoldingProductsInputProps.isLoading;
    const kind = injectiveHoldingProductsInputProps.kind;
    const onSelected = injectiveHoldingProductsInputProps.onSelected;
    const oracles = injectiveHoldingProductsInputProps.oracles;
    const products = injectiveHoldingProductsInputProps.products;
    const assistant = Recoil_useRecoilValue_5D3EEEEA(assistant_1);
    const filteredView = Recoil_useRecoilValue_5D3EEEEA(filteredView_1);
    const reccomendedProducts = products;
    return createElement(Row, {
        classes: ofArray([utils$002Emodule["gap-m"], single$002Dproducts$002Emodule["products-list"]]),
        children: toList(delay(() => (isLoading ? singleton_1(createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["full-width"], "text-exo-primary"]),
            children: singleton(createElement(ButtonProgressLoader, {
                classes: empty(),
            })),
        })) : singleton_1(createElement(RenderInjectiveHoldingCard, {
            products: reccomendedProducts,
            oracles: oracles,
            kind: kind,
            isReccomended: (product) => ((tokens) => {
                if (filteredView) {
                    if (assistant != null) {
                        toConsole(printf("Recommended %A"))(reccomendedProducts);
                        return contains_1([product, tokens], reccomendedProducts, {
                            Equals: (x, y) => equalArrays(x, y),
                            GetHashCode: (x) => arrayHash(x),
                        });
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }),
            onSelected: onSelected,
        }))))),
    });
}

export function RenderExoticProductCard(renderExoticProductCardInputProps) {
    const onSelected = renderExoticProductCardInputProps.onSelected;
    const products = renderExoticProductCardInputProps.products;
    const patternInput = Recoil_useRecoilState_Z74A95619(selectedProductGroup_1);
    const themeConfig = Recoil_useRecoilValue_5D3EEEEA(themeConfig_1);
    return react.createElement(react.Fragment, {}, ...map((tupledArg_3) => {
        const products_1 = tupledArg_3[1];
        const product = head(products_1);
        const tokens = product[1];
        const product_1 = product[0];
        const groupCount = length(products_1) | 0;
        return createElement("div", {
            className: join(" ", [single$002Dproducts$002Emodule["list-group-card"], (themeConfig === "theme-dark") ? single$002Dproducts$002Emodule["list-group-card-dark"] : ""]),
            onClick: (_arg1) => {
                patternInput[1](new SelectedProductGroup(1, product_1.Underlying, product_1.Product.SpecificProduct));
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["list-group-card-header"], (themeConfig === "theme-dark") ? single$002Dproducts$002Emodule["list-group-card-header-dark"] : ""]),
                children: Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
                    depositMint: tokens.Deposit,
                }), toText(interpolate("%P()", [tokens.Deposit.symbol]))]),
            }), createElement("div", {
                className: join(" ", [utils$002Emodule.row, utils$002Emodule.center, utils$002Emodule["gap-s"], utils$002Emodule["margin-s-top"]]),
                children: react.createElement(react.Fragment, {}, ...map_1((underlying_1) => createElement("div", {
                    className: join(" ", [utils$002Emodule.center, utils$002Emodule["gap-s"], utils$002Emodule.col]),
                    children: Interop_reactApi.Children.toArray([createElement(CurrencyIcon, {
                        depositMint: underlying_1,
                    }), toText(interpolate("%P()", [underlying_1.symbol]))]),
                }), tokens.Underlying)),
            }), createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["list-group-card-line"], utils$002Emodule["margin-m-top"]]),
            }), createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy"], utils$002Emodule["margin-m-top"]]),
                children: Interop_reactApi.Children.toArray([toText(interpolate("Upto %P()%%", [product_1.Holding.EstimatedYieldHigh]))]),
            }), createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy-subheader"]]),
                children: Interop_reactApi.Children.toArray([toText(interpolate("Estimated APY", []))]),
            }), createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["list-group-card-line"], utils$002Emodule["margin-m-top"]]),
            }), createElement("div", {
                className: join(" ", [single$002Dproducts$002Emodule["list-group-card-apy-subheader"], utils$002Emodule["margin-m-top"], utils$002Emodule["margin-m-bottom"]]),
                children: Interop_reactApi.Children.toArray([(groupCount > 1) ? toText(interpolate("%P() available products", [groupCount])) : toText(interpolate("%P() available product", [groupCount]))]),
            })]),
        });
    }, map((tupledArg_1) => [tupledArg_1[0], filter((tupledArg_2) => {
        if (tupledArg_2[0].Product.SpecificProduct.tag === 0) {
            return true;
        }
        else {
            return false;
        }
    }, tupledArg_1[1])], List_groupBy((tupledArg) => tupledArg[0].Underlying, products, {
        Equals: (x, y) => equalsWith((x_1, y_1) => compare_1(x_1, y_1), x, y),
        GetHashCode: (x) => arrayHash(x),
    }))));
}

export function ExoticProducts(exoticProductsInputProps) {
    const setLoading = exoticProductsInputProps.setLoading;
    const isLoading = exoticProductsInputProps.isLoading;
    const onSelected = exoticProductsInputProps.onSelected;
    const products = exoticProductsInputProps.products;
    return createElement(Row, {
        classes: ofArray([utils$002Emodule["gap-m"], single$002Dproducts$002Emodule["products-list"]]),
        children: toList(delay(() => (isLoading ? singleton_1(createElement(Row, {
            classes: ofArray([utils$002Emodule.center, utils$002Emodule["full-width"], "text-exo-primary"]),
            children: singleton(createElement(ButtonProgressLoader, {
                classes: empty(),
            })),
        })) : singleton_1(createElement(RenderExoticProductCard, {
            products: products,
            onSelected: onSelected,
        }))))),
    });
}

export const noProducts = createElement(Row, {
    classes: singleton(utils$002Emodule.center),
    children: singleton(createElement("p", {
        className: join(" ", [utils$002Emodule["text-m"], "text-exo-primary"]),
        children: "No products available right now, come back later",
    })),
});

export const noProducts24h = createElement(Row, {
    classes: singleton(utils$002Emodule.center),
    children: singleton(createElement("p", {
        className: join(" ", [utils$002Emodule["text-m"], utils$002Emodule["text-center"], utils$002Emodule["boldness-500"], utils$002Emodule["text-high-risk"]]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            children: ["We are testing the exotic smart contract V2."],
        }), createElement("div", {
            children: ["Please come back tomorrow (June 17, 1:00 PM UTC) for new products."],
        })]),
    })),
});

export const loading = createElement(Row, {
    classes: singleton(utils$002Emodule.center),
    children: singleton(createElement("p", {
        className: join(" ", [utils$002Emodule["text-m"]]),
        children: "Loading Products...",
    })),
});

export function RenderSingleProducts(renderSingleProductsInputProps) {
    const isLoading = renderSingleProductsInputProps.isLoading;
    const exoticProducts = renderSingleProductsInputProps.exoticProducts;
    const products = renderSingleProductsInputProps.products;
    const setShowMobileFilter = renderSingleProductsInputProps.setShowMobileFilter;
    const showMobileFilter = renderSingleProductsInputProps.showMobileFilter;
    const setIsLoading = renderSingleProductsInputProps.setIsLoading;
    const setSelectedProductPublicKey = Recoil_useRecoilState_Z74A95619(selectedProductPublicKey_1)[1];
    const callProducts = useReact_useMemo_CF4EA67(() => filter((tupledArg) => equals(tupledArg[0].Kind, new Kind(1)), products), [products]);
    const putProducts = useReact_useMemo_CF4EA67(() => filter((tupledArg_1) => equals(tupledArg_1[0].Kind, new Kind(2)), products), [products]);
    return createElement("div", {
        className: join(" ", []),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["padding-s"]]),
            children: Interop_reactApi.Children.toArray([createElement(Content, {
                x: createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((((length(products) === 0) ? (length(exoticProducts) === 0) : false) ? (!isLoading) : false) ? singleton_1(noProducts) : ((((length(products) === 0) ? (length(exoticProducts) === 0) : false) ? isLoading : false) ? singleton_1(createElement(Row, {
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["full-width"], "text-exo-primary"]),
                        children: singleton(createElement(ButtonProgressLoader, {
                            classes: empty(),
                        })),
                    })) : append(singleton_1(createElement(Header, null)), delay(() => append(singleton_1(createElement(RenderHeadersDcnCall, null)), delay(() => append(singleton_1(createElement(DualCurrencyProducts, {
                        products: callProducts,
                        onSelected: setSelectedProductPublicKey,
                        kind: new Kind(1),
                        isLoading: isLoading,
                        setLoading: setIsLoading,
                    })), delay(() => append(singleton_1(createElement(RenderHeadersDcnPut, null)), delay(() => singleton_1(createElement(DualCurrencyProducts, {
                        products: putProducts,
                        onSelected: setSelectedProductPublicKey,
                        kind: new Kind(2),
                        isLoading: isLoading,
                        setLoading: setIsLoading,
                    })))))))))))))))),
                }),
                classes: empty(),
            })]),
        })]),
    });
}

export function RenderInjectiveSingleProducts(renderInjectiveSingleProductsInputProps) {
    const isLoading = renderInjectiveSingleProductsInputProps.isLoading;
    const oracles = renderInjectiveSingleProductsInputProps.oracles;
    const products = renderInjectiveSingleProductsInputProps.products;
    const setShowMobileFilter = renderInjectiveSingleProductsInputProps.setShowMobileFilter;
    const showMobileFilter = renderInjectiveSingleProductsInputProps.showMobileFilter;
    const setIsLoading = renderInjectiveSingleProductsInputProps.setIsLoading;
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment_1);
    const setSelectedInjectiveProductAddress = Recoil_useRecoilState_Z74A95619(selectedInjectiveProductAddress_1)[1];
    const callProducts = useReact_useMemo_CF4EA67(() => {
        try {
            return products.filter((tupledArg) => equals(InjectiveProduct__GetKind(tupledArg[0].Product), new InjectiveProductKind(0)));
        }
        catch (matchValue) {
            return [];
        }
    }, [products, environment]);
    const putProducts = useReact_useMemo_CF4EA67(() => {
        try {
            return products.filter((tupledArg_1) => equals(InjectiveProduct__GetKind(tupledArg_1[0].Product), new InjectiveProductKind(1)));
        }
        catch (matchValue_1) {
            return [];
        }
    }, [products, environment]);
    return createElement("div", {
        className: join(" ", []),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", [utils$002Emodule["padding-s"]]),
            children: Interop_reactApi.Children.toArray([createElement(Content, {
                x: createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (equalsWith((x, y) => compareArrays(x, y), products, null) ? singleton_1(noProducts) : (((products.length === 0) ? (!isLoading) : false) ? singleton_1(noProducts) : (((products.length === 0) ? isLoading : false) ? singleton_1(createElement(Row, {
                        classes: ofArray([utils$002Emodule.center, utils$002Emodule["full-width"], "text-exo-primary"]),
                        children: singleton(createElement(ButtonProgressLoader, {
                            classes: empty(),
                        })),
                    })) : append(singleton_1(createElement(Header, null)), delay(() => append(singleton_1(createElement(RenderHeadersDcnCall, null)), delay(() => append(singleton_1(createElement(InjectiveHoldingProducts, {
                        products: callProducts,
                        oracles: oracles,
                        onSelected: setSelectedInjectiveProductAddress,
                        kind: new InjectiveProductKind(0),
                        isLoading: isLoading,
                        setLoading: setIsLoading,
                    })), delay(() => append(singleton_1(createElement(RenderHeadersDcnPut, null)), delay(() => singleton_1(createElement(InjectiveHoldingProducts, {
                        products: putProducts,
                        oracles: oracles,
                        onSelected: setSelectedInjectiveProductAddress,
                        kind: new InjectiveProductKind(1),
                        isLoading: isLoading,
                        setLoading: setIsLoading,
                    }))))))))))))))))),
                }),
                classes: empty(),
            })]),
        })]),
    });
}

export function ProductsList(productsListInputProps) {
    const setExoticProducts = productsListInputProps.setExoticProducts;
    const exoticProducts = productsListInputProps.exoticProducts;
    const setProducts = productsListInputProps.setProducts;
    const products = productsListInputProps.products;
    const walletCtx = useWallet();
    const productType = Recoil_useRecoilState_Z74A95619(selectedProductType)[0];
    const kind = useReact_useMemo_CF4EA67(() => ((productType.tag === 1) ? (new Kind(2)) : ((productType.tag === 2) ? (new Kind(0)) : (new Kind(1)))), [productType]);
    const tokens = Recoil_useRecoilState_Z74A95619(tokens_3)[0];
    const snackbar = useSnackbar();
    const connectionState = Hooks_useHub();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const setIsLoading = patternInput_2[1];
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const environment = Recoil_useRecoilValue_5D3EEEEA(solanaEnvironment);
    const productsUpdates = Hooks_useStreamToObservable(connectionState, signalRproxy.GetFlexProducts(environment), [tokens, kind, environment, chain]);
    const patternInput_3 = Hooks_useSplitObservableWithUpdates(Hooks_useStreamToObservable(connectionState, signalRproxy.GetExoticProducts(environment), [tokens, environment, chain]));
    const patternInput_4 = Hooks_useSplitObservableWithUpdates(productsUpdates);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    Hooks_useSubscribeObservable(merge(patternInput_4[1], patternInput_3[1]), (x) => {
        console.error(some(x));
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, x.message, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(0, false));
    }, []);
    Hooks_useSubscribeObservable(patternInput_3[0], (data) => {
        startImmediate(singleton_2.Delay(() => singleton_2.Bind(processExoticProductData(data, tokens), (_arg1) => {
            let data_2;
            const data_1 = _arg1;
            setExoticProducts((data_1.tag === 1) ? empty() : (data_2 = data_1.fields[0], (console.log(some("[Exotic Products]"), data_2), data_2)));
            return singleton_2.Zero();
        })));
    }, [tokens, walletCtx.publicKey]);
    Hooks_useSubscribeObservable(patternInput_4[0], (data_4) => {
        startImmediate(singleton_2.Delay(() => singleton_2.Bind(processFlexProductData(data_4, tokens), (_arg2) => {
            const data_6 = Result_Map((l) => filter((tupledArg) => {
                const x_1 = tupledArg[0];
                if (!equals(x_1.PublicKey, new PublicKey(0, "4m6hMbyYeUVSTANqiVmwBqAmYSqcv6j9oh9jN7z8yER3"))) {
                    return !equals(x_1.PublicKey, new PublicKey(0, "DqPuqxAie27f77AL7Tf4inMWNAdE2qryNW67dKrgXcgy"));
                }
                else {
                    return false;
                }
            }, l), _arg2);
            setProducts((data_6.tag === 1) ? (console.error(some(data_6.fields[0])), empty()) : data_6.fields[0]);
            setIsLoading(false);
            return singleton_2.Zero();
        })));
    }, [tokens, walletCtx.publicKey]);
    return errorBoundary(createElement(RenderSingleProducts, {
        setIsLoading: setIsLoading,
        showMobileFilter: patternInput_5[0],
        setShowMobileFilter: patternInput_5[1],
        products: products,
        exoticProducts: exoticProducts,
        isLoading: patternInput_2[0],
    }));
}

export function getOnChainHoldingProductData(config, environment, endpoints, isProductPage) {
    return singleton_2.Delay(() => {
        const wasmGrpcApi = chainGrpcWasmApi(endpoints.grpc, environment);
        const holdingContract = config.InjectiveHoldingContract;
        return singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, "\"get_holding_ids\""), (_arg1) => singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, "\"get_product_ids\""), (_arg2) => {
            const holding_id_arr = getStringArrayFromJsonString(_arg1);
            const product_id_arr = getStringArrayFromJsonString(_arg2);
            return singleton_2.Bind(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                const queryHoldings = new QueryHoldings(new Ids(holding_id_arr));
                return singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, toString(0, Auto_generateBoxedEncoder_Z20B7B430(QueryHoldings$reflection(), new CaseStrategy(2), void 0, void 0)(queryHoldings))), (_arg3) => singleton_2.Return(map2((id, holdingRes) => deserializeHolding(id, JSON.stringify(holdingRes)), holding_id_arr, getStringArrayFromJsonString(_arg3)).filter((holding) => equals(holding.State, new HoldingState(1)))));
            }), (_arg4) => {
                console.log(some(_arg4));
                return singleton_2.Return([]);
            })), (_arg5) => {
                const holdings_1 = _arg5;
                return singleton_2.Bind(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                    const queryProducts = new QueryProducts(new Ids(product_id_arr));
                    return singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, toString(0, Auto_generateBoxedEncoder_Z20B7B430(QueryProducts$reflection(), new CaseStrategy(2), void 0, void 0)(queryProducts))), (_arg6) => singleton_2.Return(map2((id_1, productRes) => deserializeProduct(id_1, JSON.stringify(productRes)), product_id_arr, getStringArrayFromJsonString(_arg6))));
                }), (_arg7) => {
                    console.log(some(_arg7));
                    return singleton_2.Return([]);
                })), (_arg8) => {
                    const products_1 = _arg8;
                    const oracleApi = indexerGrpcOracleApi(endpoints.indexer);
                    return singleton_2.Bind(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(getOracleList(oracleApi), (_arg9) => singleton_2.Return(_arg9))), (_arg10) => singleton_2.Return([]))), (_arg11) => {
                        const oracles_1 = _arg11;
                        if (isProductPage) {
                            const now = utcNow();
                            return singleton_2.Return([holdings_1.filter((h) => {
                                if (compare_2(h.EndCollection, now) > 0) {
                                    return compare_2(h.StartCollection, now) <= 0;
                                }
                                else {
                                    return false;
                                }
                            }), products_1, [], [], oracles_1]);
                        }
                        else {
                            const holdingsWithOption = holdings_1.filter((h_1) => {
                                if (h_1.OptionContract.tag === 0) {
                                    return false;
                                }
                                else {
                                    return true;
                                }
                            });
                            const maxQueryNumber = 30;
                            const ids_2 = chunkBySize(maxQueryNumber, map_1((holding_1) => holding_1.Id, holdingsWithOption));
                            return singleton_2.Bind(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                                if (holdingsWithOption.length === 0) {
                                    return singleton_2.Return([]);
                                }
                                else if (holdingsWithOption.length <= maxQueryNumber) {
                                    return singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, "\"get_options\""), (_arg12) => singleton_2.Return(deserializeOptionArray(_arg12)));
                                }
                                else {
                                    const nbQueriesNeeded = holdingsWithOption.length / maxQueryNumber;
                                    return singleton_2.Bind(parallel(map_1((msg_1) => singleton_2.Delay(() => singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, msg_1), (_arg13) => singleton_2.Return(_arg13))), map_1((n) => {
                                        const queryOptionsMsg_1 = new QueryOptionsByIds(new Ids(ids_2[n]));
                                        return toString(0, Auto_generateBoxedEncoder_Z20B7B430(QueryOptionsByIds$reflection(), new CaseStrategy(2), void 0, void 0)(queryOptionsMsg_1));
                                    }, toArray(rangeDouble(0, 1, (((holdingsWithOption.length % maxQueryNumber) === 0) ? (~(~Math.floor(nbQueriesNeeded))) : ((~(~Math.floor(nbQueriesNeeded))) + 1)) - 1))))), (_arg14) => singleton_2.Return(concat(map_1((jsonString_2) => deserializeOptionArray(jsonString_2), _arg14))));
                                }
                            }), (_arg15) => {
                                console.log(some(_arg15));
                                return singleton_2.Return([]);
                            })), (_arg16) => singleton_2.Bind(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                                if (holdingsWithOption.length === 0) {
                                    return singleton_2.Return([]);
                                }
                                else if (holdingsWithOption.length <= maxQueryNumber) {
                                    return singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, "\"get_rfqs\""), (_arg17) => singleton_2.Return(deserializeRfqArray(_arg17)));
                                }
                                else {
                                    const nbQueriesNeeded_1 = holdingsWithOption.length / maxQueryNumber;
                                    return singleton_2.Bind(parallel(map_1((msg_3) => singleton_2.Delay(() => singleton_2.Bind(getWasmSmartQuery(wasmGrpcApi, holdingContract, msg_3), (_arg18) => singleton_2.Return(_arg18))), map_1((n_1) => {
                                        const queryRfqsMsg_1 = new QueryRfqsByIds(new Ids(ids_2[n_1]));
                                        return toString(0, Auto_generateBoxedEncoder_Z20B7B430(QueryRfqsByIds$reflection(), new CaseStrategy(2), void 0, void 0)(queryRfqsMsg_1));
                                    }, toArray(rangeDouble(0, 1, (((holdingsWithOption.length % maxQueryNumber) === 0) ? (~(~Math.floor(nbQueriesNeeded_1))) : ((~(~Math.floor(nbQueriesNeeded_1))) + 1)) - 1))))), (_arg19) => singleton_2.Return(concat(map_1((jsonString_3) => deserializeRfqArray(jsonString_3), _arg19))));
                                }
                            }), (_arg20) => {
                                console.log(some(_arg20));
                                return singleton_2.Return([]);
                            })), (_arg21) => singleton_2.Return([holdings_1, products_1, _arg16, _arg21, oracles_1])));
                        }
                    });
                });
            });
        }));
    });
}

export function getOnChainHoldingOptionRfqData(holdings, options, rfqs) {
    return singleton_2.Delay(() => singleton_2.Bind(parallel(map_1((holding) => singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
        const optionContract = holding.OptionContract;
        const bidType = holding.BidType;
        return singleton_2.Bind(singleton_2.Delay(() => ((optionContract.tag === 0) ? singleton_2.Return(void 0) : singleton_2.Return(options.find((o) => (optionContract.fields[0] === o.Address))))), (_arg1) => singleton_2.Bind(singleton_2.Delay(() => {
            if (bidType.tag === 0) {
                const contractState = bidType.fields[0];
                return (contractState.tag === 0) ? singleton_2.Return(void 0) : singleton_2.Return(rfqs.find((r) => (contractState.fields[0] === r.Address)));
            }
            else {
                return singleton_2.Return(void 0);
            }
        }), (_arg2) => singleton_2.Return([holding, _arg1, _arg2])));
    }), (_arg3) => singleton_2.Return([holding, void 0, void 0]))), holdings)), (_arg4) => singleton_2.Return(_arg4)));
}

export function InjectiveProductList(injectiveProductListInputProps) {
    const refreshDataToggle = injectiveProductListInputProps.refreshDataToggle;
    const setOracles = injectiveProductListInputProps.setOracles;
    const oracles = injectiveProductListInputProps.oracles;
    const setInjectiveProducts = injectiveProductListInputProps.setInjectiveProducts;
    const injectiveProducts = injectiveProductListInputProps.injectiveProducts;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setIsLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const config = Recoil_useRecoilValue_5D3EEEEA(config_1);
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const environment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment_1);
    const tokens = Recoil_useRecoilValue_5D3EEEEA(tokens_3);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
            setIsLoading(true);
            setInjectiveProducts([]);
            setOracles([]);
            if (config == null) {
                setInjectiveProducts([]);
                setOracles([]);
                setIsLoading(false);
                return singleton_2.Zero();
            }
            else {
                return singleton_2.Bind(getOnChainHoldingProductData(config, environment, getNetworkEndpoints(getNetworkByInjectiveEnvironment(environment)), true), (_arg1) => singleton_2.Bind(getOnChainHoldingOptionRfqData(_arg1[0], [], []), (_arg2) => singleton_2.Bind(Api.GetInjectiveHideProducts(), (_arg3) => singleton_2.Bind(processInjectiveHoldingProductData(_arg2, _arg1[1], tokens, true, _arg3), (_arg4) => {
                    const res = _arg4;
                    setInjectiveProducts((res.tag === 1) ? [] : toArray_1(res.fields[0]));
                    setOracles(_arg1[4]);
                    setIsLoading(false);
                    return singleton_2.Zero();
                }))));
            }
        }), (_arg5) => {
            setIsLoading(false);
            return singleton_2.Zero();
        })));
    }, [tokens, config, chain, environment, refreshDataToggle]);
    return errorBoundary(createElement(RenderInjectiveSingleProducts, {
        setIsLoading: setIsLoading,
        showMobileFilter: patternInput_1[0],
        setShowMobileFilter: patternInput_1[1],
        products: injectiveProducts,
        oracles: oracles,
        isLoading: patternInput[0],
    }));
}

export function SolanaSingleProducts(solanaSingleProductsInputProps) {
    const setSelectedProductGroup = solanaSingleProductsInputProps.setSelectedProductGroup;
    const selectedProductGroup = solanaSingleProductsInputProps.selectedProductGroup;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const products = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const exoticProducts = patternInput_1[0];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement(ProductsList, {
            products: products,
            setProducts: patternInput[1],
            exoticProducts: exoticProducts,
            setExoticProducts: patternInput_1[1],
        })), delay(() => append((selectedProductGroup.tag === 1) ? singleton_1(null) : ((selectedProductGroup.tag === 2) ? singleton_1(null) : ((selectedProductGroup.tag === 3) ? singleton_1(null) : singleton_1(createElement(FlexProductGroup, {
            products: products,
            underlyingMint: selectedProductGroup.fields[0],
            kind: selectedProductGroup.fields[1],
            onClose: () => {
                setSelectedProductGroup(new SelectedProductGroup(3));
            },
            hideProductSelection: false,
        })))), delay(() => singleton_1(ExoticProductGroupDetail(exoticProducts, false)))))))))),
    });
}

export function InjectiveSingleProducts(injectiveSingleProductsInputProps) {
    const setSelectedProductGroup = injectiveSingleProductsInputProps.setSelectedProductGroup;
    const selectedProductGroup = injectiveSingleProductsInputProps.selectedProductGroup;
    const patternInput = useFeliz_React__React_useState_Static_1505([]);
    const injectiveProducts = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const oracles = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const refreshDataToggle = patternInput_2[0];
    const injectiveEnvironment = Recoil_useRecoilValue_5D3EEEEA(injectiveEnvironment_1);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505([]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Api.GetInjectiveUiFormat(), (_arg1) => {
            patternInput_3[1](_arg1);
            return singleton_2.Zero();
        })), (_arg2) => {
            return singleton_2.Zero();
        })));
    }, [injectiveEnvironment]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement(InjectiveProductList, {
            injectiveProducts: injectiveProducts,
            setInjectiveProducts: patternInput[1],
            oracles: oracles,
            setOracles: patternInput_1[1],
            refreshDataToggle: refreshDataToggle,
        })), delay(() => ((selectedProductGroup.tag === 2) ? singleton_1(createElement(InjectiveHoldingGroupDetail, {
            products: injectiveProducts,
            oracles: oracles,
            underlyingMint: selectedProductGroup.fields[0],
            kind: selectedProductGroup.fields[1],
            onClose: () => {
                setSelectedProductGroup(new SelectedProductGroup(3));
            },
            hideProductSelection: false,
            refreshDataToggle: refreshDataToggle,
            setRefreshDataToggle: patternInput_2[1],
            isCollapsedProductRecap: true,
            uiFormat: patternInput_3[0],
        })) : singleton_1(null)))))))),
    });
}

export function SingleProducts() {
    const chain = Recoil_useRecoilValue_5D3EEEEA(blockchain);
    const patternInput = Recoil_useRecoilState_Z74A95619(selectedProductGroup_1);
    const setSelectedProductGroup = patternInput[1];
    const selectedProductGroup = patternInput[0];
    const solana = createElement(SolanaSingleProducts, {
        selectedProductGroup: selectedProductGroup,
        setSelectedProductGroup: setSelectedProductGroup,
    });
    const injective = createElement(InjectiveSingleProducts, {
        selectedProductGroup: selectedProductGroup,
        setSelectedProductGroup: setSelectedProductGroup,
    });
    if (chain.tag === 1) {
        return injective;
    }
    else {
        return solana;
    }
}

